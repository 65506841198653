import { Widget } from 'Widget/class/Widget'
import { IPusherSuccessEvent } from 'Widget/types'

import { payloadDemoToCreativeAdapter, payloadToCreativeAdapter } from 'lib/adapters/creativePayloadAdapter'
import { fetchSspMediaCreative, fetchSspTextCreative } from 'lib/api/modules/widget'
import { Analytic } from 'lib/helpers/Analytic'
import { Logger } from 'lib/helpers/handleLog'
import { isSspFormat } from 'lib/helpers/isSsp'
import { AdFormat } from 'lib/types/ad-format'
import { IStreamInfo } from 'lib/types/creative'
import {
  ICreativePayload,
  ICreativesPayload,
  IDemoCreativePayload,
  IPromoCreativePayload,
  IRealDemoCreativePayload,
  ISspCreativePayload,
  ISspStreamInfo,
} from 'lib/types/payloads/creative'

type DemoCreativeType = IDemoCreativePayload | IRealDemoCreativePayload

type SspCreativeType = ISspCreativePayload & {impression_slug: string}

export class Preparer {
  widget: Widget;

  constructor (widget: Widget) {
    this.widget = widget
  }

  private async fetchSspCreativesData (sspCreatives: ICreativePayload[], stream: IStreamInfo) {
    const creativesData: SspCreativeType[] = []
    let lastCreativeId = null

    for (let i = 0; i < sspCreatives.length; i++) {
      const creative = sspCreatives[i]
      const isCpcRequest = creative.ad_set.format === AdFormat.YANDEX_PF

      try {
        let sspCreative = null

        if ([AdFormat.YANDEX_FS, AdFormat.YANDEX_PF].includes(creative.ad_set.format)) {
          const params: ISspStreamInfo = {
            ...stream,
            ...creative.attachments.video?.extend?.stream,
            platform: this.widget.data.value.platform,
            allow_adult_content: this.widget.data.value.allowAdultContent,
            debug: this.widget.isDebug.value,
          }

          if (lastCreativeId) {
            params.skip_token = lastCreativeId
          }

          sspCreative = await fetchSspMediaCreative(params, isCpcRequest)

          if (sspCreative && sspCreative.vast_path) {
            creativesData.push({
              ...sspCreative,
              impression_slug: creative.impression_slug as string,
            })

            if (sspCreative?.id) {
              lastCreativeId = sspCreative.id
            }
          }
        }
        else if (creative.ad_set.format === AdFormat.YANDEX_TEXT) {
          const params: ISspStreamInfo = {
            ...stream,
            ...creative.attachments.unit?.extend?.stream,
            platform: this.widget.data.value.platform,
            allow_adult_content: this.widget.data.value.allowAdultContent,
            debug: this.widget.isDebug.value,
          }

          sspCreative = await fetchSspTextCreative(params)

          if (sspCreative && sspCreative.chatbot_text) {
            creativesData.push({
              ...sspCreative,
              impression_slug: creative.impression_slug as string,
            })
          }
        }
      }
      catch (err) {
        Logger.warning('Error getting creative from SSP', false, err)
      }
    }

    return creativesData
  }

  private prepareSspCreativeMedia (sspCreative: SspCreativeType, creative: ICreativePayload) {
    if (
      creative.attachments.video &&
      creative.attachments.video?.extend &&
      sspCreative.vast_path
    ) {
      creative.attachments.video.path = sspCreative.vast_path
      creative.attachments.video.extend.ssp = sspCreative
      creative.attachments.video.properties.duration = (sspCreative.duration || 0) + 0.1
      creative.attachments.video.extend.metacount = sspCreative.meta_count
      creative.attachments.video.extend.rtbcount = sspCreative.rtb_count
      creative.attachments.video.extend.pixel_impressions = sspCreative.pixel_impressions
      creative.ad_set.advertiser.legal_name = this.transformAdvertiserText(sspCreative.advertiser)

      return creative
    }

    return null
  }

  private prepareSspCreativeText (sspCreative: SspCreativeType, creative: ICreativePayload) {
    if (
      creative.attachments.unit &&
      creative.attachments.unit?.extend
    ) {
      creative.attachments.unit.extend.ssp = sspCreative
      creative.attachments.unit.extend.metacount = sspCreative.meta_count
      creative.attachments.unit.extend.rtbcount = sspCreative.rtb_count
      creative.attachments.unit.extend.pixel_impressions = sspCreative.pixel_impressions

      return creative
    }

    return null
  }

  private transformAdvertiserText (text: string) {
    return 'Реклама ' + text
      .replace(/общество с ограниченной ответственностью/i, 'ООО')
      .replace('INN', 'ИНН')
  }

  private resolveAllCreatives (creatives: ICreativePayload[], sspCreativesData: SspCreativeType[]) {
    const resolvedCreatives = []

    for (let i = 0; i < creatives.length; i++) {
      const creative = creatives[i]
      const findSspCreative = sspCreativesData.find(sspCreative => sspCreative.impression_slug === creative.impression_slug)

      if (findSspCreative) {
        let resolvedSspCreative: ICreativePayload | null = null

        if ([AdFormat.YANDEX_FS, AdFormat.YANDEX_PF].includes(creative.ad_set.format)) {
          resolvedSspCreative = this.prepareSspCreativeMedia(findSspCreative, creative)
        }
        if (creative.ad_set.format === AdFormat.YANDEX_TEXT) {
          resolvedSspCreative = this.prepareSspCreativeText(findSspCreative, creative)
        }

        if (resolvedSspCreative) {
          resolvedCreatives.push(resolvedSspCreative)
        }
      }

      if (!isSspFormat(creative.ad_set.format)) {
        resolvedCreatives.push(creative)
      }
    }

    return resolvedCreatives
  }

  async prepareCreatives (event: IPusherSuccessEvent<ICreativesPayload>) {
    const { items: creatives, stream } = event.data
    const sspCreatives = creatives.filter(creative => isSspFormat(creative.ad_set.format))
    const sspCreativesData = await this.fetchSspCreativesData(sspCreatives, stream)
    const resolvedCreatives = this.resolveAllCreatives(creatives, sspCreativesData)

    Logger.debug('event.data', event.data)
    Logger.debug('sspCreatives', sspCreatives)
    Logger.debug('sspCreativesData', sspCreativesData)
    Logger.debug('resolvedCreatives', resolvedCreatives)

    const ad = resolvedCreatives.map((item: ICreativePayload) => {
      const creative = payloadToCreativeAdapter(item, stream)
      Analytic.ga('ecommerce:addItem', {
        id: creative.uuid,
        name: creative.slug,
        price: creative.viewersCount,
        quantity: '1',
      })
      return creative
    })
    this.widget.creativesLoop.addAd(ad)
  }

  prepareDemoCreatives (event: IPusherSuccessEvent<DemoCreativeType[]>) {
    const ad = event.data.map((item: DemoCreativeType) => payloadDemoToCreativeAdapter(item))
    this.widget.creativesLoop.addDemo(ad)
  }

  preparePromoCreatives (event: IPusherSuccessEvent<IPromoCreativePayload[]>) {
    const ad = event.data.map((item: IPromoCreativePayload) => payloadDemoToCreativeAdapter(item))
    this.widget.creativesLoop.addAd(ad)
  }
}
