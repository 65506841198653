<template>
  <ElFormItem
    :label="locale.label"
    :prop="field"
    :error="errors[field]"
  >
    <ElInput
      type="textarea"
      :placeholder="locale.placeholder"
      v-model="model[field]"
      :maxlength="maxLength"
      resize="none"
      show-word-limit
      :autosize="{ minRows: 2 }"
    />
  </ElFormItem>
</template>

<script>
import ElFormItem from 'element/ElFormItem'
import ElInput from 'element/ElInput'

export default {
  components: {
    ElFormItem,
    ElInput,
  },

  props: {
    model: VueTypes.object.isRequired,
  },

  data: () => ({
    field: 'chatbot_text',
  }),

  computed: {
    maxLength: () => 150,
    locale: ({ $locale }) => ({
      label: $locale('form.data.fields.chatbotText.label'),
      placeholder: $locale('form.data.fields.chatbotText.placeholder'),
    }),
    errors: ({ $store }) => $store.getters['creative/errors'],
  },
}
</script>
