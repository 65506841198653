import BaseModel from '@/models/BaseModel'

export default class Creative extends BaseModel {
  static schema = {
    slug: '',
    ad_set: null,
    title: '',
    attachments: {
      video: null,
      unit: null,
      image: null,
    },
    product_url: '',
    chatbot_text: '',
    companion_heading: 'done',
    companion_text: 'done',
    companion_cta: 'done',
    qr_code: false,
    pixel_clicks: [
      {
        id: new Date().getTime(),
        value: '',
      },
    ],
    pixel_impressions: [
      {
        id: new Date().getTime(),
        value: '',
      },
    ],
    pixel_inspections: [
      {
        id: new Date().getTime(),
        value: '',
      },
    ],
    legal_compliance: {
      erid: {
        media: null,
        text: null,
      },
      marker: {
        media: null,
        text: null,
      },
    },
  }

  static parseRaw (data) {
    const parsed = {
      id: data.id,
      slug: data.slug,
      ad_set: data.ad_set,
      visible: data.visible,
      title: data.title,
      attachments: data.attachments || {
        video: null,
        unit: null,
        image: null,
        zip: null,
      },
      product_url: data.product_url,
      chatbot_text: data.chatbot_text,
      companion_heading: 'done',
      companion_text: 'done',
      companion_cta: 'done',
      qr_code: data.qr_code,
      pixel_clicks: data.pixel_clicks,
      pixel_impressions: data.pixel_impressions,
      pixel_inspections: data.pixel_inspections,
      // legal_compliance: data.legal_compliance,
      legal_compliance: {
        erid: {
          media: data?.legal_compliance?.erid?.media || null,
          text: data?.legal_compliance?.erid?.text || null,
        },
        marker: {
          media: data?.legal_compliance?.marker?.media || null,
          text: data?.legal_compliance?.marker?.text || null,
        },
      },
    }

    if (!data.pixel_clicks?.length) {
      parsed.pixel_clicks = [{
        id: new Date().getTime(),
        value: '',
      }]
    }
    else {
      parsed.pixel_clicks = parsed.pixel_clicks.map((item, i) => ({
        id: i,
        value: item?.value || item, // legacy, need use only value: item
      }))
    }
    if (!data.pixel_impressions?.length) {
      parsed.pixel_impressions = [{
        id: new Date().getTime(),
        value: '',
      }]
    }
    else {
      parsed.pixel_impressions = parsed.pixel_impressions.map((item, i) => ({
        id: i,
        value: item?.value || item, // legacy, need use only value: item
      }))
    }
    if (!data.pixel_inspections?.length) {
      parsed.pixel_inspections = [{
        id: new Date().getTime(),
        value: '',
      }]
    }
    else {
      parsed.pixel_inspections = parsed.pixel_inspections.map((item, i) => ({
        id: i,
        value: item?.value || item, // legacy, need use only value: item
      }))
    }

    return parsed
  }
}
