<template>
  <ElFormItem
    :label="locale.label"
    :prop="field"
    :error="errors[field]"
  >
    <ElInput
      v-for="item in links"
      :key="item.id"
      :placeholder="locale.placeholder"
      v-model="item.value"
    />
  </ElFormItem>
</template>

<script>
import ElFormItem from 'element/ElFormItem'
import ElInput from 'element/ElInput'

export default {
  components: {
    ElFormItem,
    ElInput,
  },

  data: () => ({
    field: 'pixel_clicks',
  }),

  props: {
    model: VueTypes.object.isRequired,
  },

  computed: {
    locale: ({ $locale }) => ({
      label: $locale('form.data.fields.pixelClicks.label'),
      placeholder: $locale('form.data.fields.pixelClicks.placeholder'),
    }),
    links: ({ model, field }) => model[field],
    errors: ({ $store }) => $store.getters['creative/errors'],
  },
}
</script>
