<template>
  <Advice
    :label="locale.title"
    class="show-preview"
  >
    <p>
      {{ locale.description }}
    </p>

    <ElDialog
      :title="locale.title"
      :visible.sync="dialogVisible"
      width="698px"
    >
      <Preview
        v-if="dialogVisible"
        :slug="creative.slug"
      />
    </ElDialog>

    <ElButton
      type="primary"
      @click="dialogVisible = true"
    >
      {{ locale.button }}
    </ElButton>
  </Advice>
</template>

<script>
import Advice from 'components/Advice'
import Preview from 'components/creatives/Preview'
import ElButton from 'element/ElButton'
import ElDialog from 'element/ElDialog'

export default {
  components: {
    ElButton,
    Preview,
    Advice,
    ElDialog,
  },

  data: () => ({
    dialogVisible: false,
  }),

  computed: {
    locale: ({ $locale }) => $locale('form.advice'),
    creative: ({ $store }) => $store.getters['creative/creative'],
  },
}
</script>

<style lang="scss" scoped>
.show-preview {
  ::v-deep {
    .preview-creative {
      height: 365px;

      .creative-preview {
        width: 1920px;
        height: 1080px;
        transform: scale(0.338);
      }
    }
    .el-button {
      margin-top: 8px;
    }
  }
}
</style>
