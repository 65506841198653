import { Logger } from 'lib/helpers/handleLog'
import { AdFormat } from 'lib/types/ad-format'
import { ICreative, IDemoCreative, IPromoCreative } from 'lib/types/creative'

import {
  tryPreloadImage,
  tryPreloadUnit,
  tryPreloadVideo,
} from './preloadUtils'
import { isSspFormat } from 'lib/helpers/isSsp'

export const preloadCreatives = async <T extends ICreative | IDemoCreative | IPromoCreative>(
  adSet: Array<T>,
): Promise<{
  preloaded: Array<T>
}> => {
  const preloaded: Array<T> = []

  for (const ad of adSet) {
    const { video, image, unit, zip } = ad.attachments

    if (isSspFormat(ad.adSet.format)) {
      preloaded.push(ad)
      continue
    }

    if (!video && !image && !unit && !zip) {
      Logger.warning('Undefined attachment format', false, { creativeSlug: ad.slug })
      return { preloaded }
    }

    try {
      await tryPreloadImage(ad)
      await tryPreloadVideo(ad)
      await tryPreloadUnit(ad)
      // zip does not require preloading
      preloaded.push(ad)
    }
    catch (err) {
      Logger.warning('Attachment was not preloaded before showing ads', false, { err, creativeSlug: ad.slug })
    }
  }

  return { preloaded }
}
