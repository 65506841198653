<template>
  <DashboardSection
    class="creative-uploads"
    :title="locale.title"
  >
    <AdviceManager v-if="!loading" />

    <CreativeManager
      v-if="format === 'video'"
      :title="locale.formats.video"
      type="video"
    />
    <CreativeManager
      v-if="format === 'pip_video'"
      :title="locale.formats.video"
      type="video"
    />
    <CreativeManager
      v-if="format === 'custom'"
      :title="locale.formats.html5"
      type="zip"
    />
  </DashboardSection>
</template>

<script>
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'

import AdviceManager from './components/AdviceManager'
import CreativeManager from './components/CreativeManager'

export default {
  components: {
    DashboardSection,
    CreativeManager,
    AdviceManager,
  },

  computed: {
    locale: ({ $locale }) => $locale('form.files'),
    format: ({ $store }) => $store.getters['creative/format'],
    loading: ({ $store }) => $store.getters['creative/loading'],
  },
}
</script>

<style lang="scss" scoped>
.creative-uploads {
  position: relative;
}
</style>
