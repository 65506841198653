<template>
  <DashboardSection :title="locale[field]">
    <ElFormItem
      :label="locale.label"
      :error="errors[field]"
      :prop="field"
    >
      <ElInput
        :placeholder="locale.placeholder"
        v-model="model[field]"
        maxlength="120"
        show-word-limit
      />
    </ElFormItem>
  </DashboardSection>
</template>

<script>
import DashboardSection from 'components/layouts/DashboardLayout/components/DashboardSection'
import ElFormItem from 'element/ElFormItem'
import ElInput from 'element/ElInput'

export default {
  components: {
    DashboardSection,
    ElFormItem,
    ElInput,
  },

  props: {
    model: VueTypes.object.isRequired,
  },

  data: () => ({
    field: 'title',
  }),

  computed: {
    locale: ({ $locale, field }) => ({
      title: $locale(`form.name.${field}`),
      label: $locale('form.name.label'),
      placeholder: $locale('form.name.placeholder'),
    }),
    errors: ({ $store }) => $store.getters['creative/errors'],
  },
}
</script>
