import BaseModel from '@/models/BaseModel'

const FIELDS = {
  id: 'id',
  slug: 'slug',
  campaign: 'campaign',
  campaignCategoryId: 'campaignCategoryId',
  campaignCategoryIsDarkMarket: 'campaignCategoryIsDarkMarket',
  currency: 'currency',
  title: 'title',
  title_alternative: 'title_alternative',
  description: 'description',
  platform: 'platform',
  format: 'format',
  format_edit: 'format_edit',
  start: 'start',
  end: 'end',
  bid_cap: 'bid_cap',
  impressions: 'impressions',
  frequency: 'frequency',
  frequency_count: 'frequency_count',
  frequency_period: 'frequency_period',
  streamers: 'streamers',
  exclude_streamers: 'exclude_streamers',
  broadcaster_languages: 'broadcaster_languages',
  exclude_languages: 'exclude_languages',
  countries: 'countries',
  exclude_countries: 'exclude_countries',
  age_from: 'age_from',
  age_to: 'age_to',
  gender: 'gender',
  mature: 'mature',
  tags: 'tags',
  exclude_tags: 'exclude_tags',
  time_zone: 'time_zone',
  target_ctr: 'target_ctr',
  target_cpa: 'target_cpa',
  agencies: 'agencies',
  min_cpm: 'min_cpm',
  max_cpm: 'max_cpm',
}

export default class Group extends BaseModel {
  static schema = {
    [FIELDS.campaign]: '',
    [FIELDS.campaignCategoryId]: '',
    [FIELDS.campaignCategoryIsDarkMarket]: false,
    [FIELDS.currency]: '',
    [FIELDS.slug]: '',
    [FIELDS.title]: '',
    [FIELDS.title_alternative]: '',
    [FIELDS.description]: '',
    [FIELDS.platform]: 'twitch',
    [FIELDS.format]: null,
    [FIELDS.format_edit]: true,
    [FIELDS.start]: null,
    [FIELDS.end]: null,
    [FIELDS.bid_cap]: '',
    [FIELDS.impressions]: '',
    [FIELDS.frequency]: '',
    [FIELDS.frequency_count]: '',
    [FIELDS.frequency_period]: null,
    [FIELDS.streamers]: [],
    [FIELDS.exclude_streamers]: false,
    [FIELDS.broadcaster_languages]: [],
    [FIELDS.exclude_languages]: false,
    [FIELDS.countries]: [],
    [FIELDS.exclude_countries]: false,
    [FIELDS.age_from]: '',
    [FIELDS.age_to]: '',
    [FIELDS.gender]: 'all',
    [FIELDS.mature]: false,
    [FIELDS.tags]: [],
    [FIELDS.exclude_tags]: false,
    [FIELDS.time_zone]: null,
    [FIELDS.target_ctr]: null,
    [FIELDS.target_cpa]: null,
    [FIELDS.agencies]: [],
    [FIELDS.min_cpm]: null,
    [FIELDS.max_cpm]: null,
  }

  static aliases = {
    [FIELDS.bid_cap]: 'bidCap',
    [FIELDS.frequency_count]: 'frequencyCount',
    [FIELDS.frequency_period]: 'frequencyPeriod',
    [FIELDS.exclude_streamers]: 'excludeStreamers',
    [FIELDS.broadcaster_languages]: 'languages',
    [FIELDS.exclude_languages]: 'excludeLanguages',
    [FIELDS.exclude_countries]: 'excludeCountries',
    [FIELDS.age_from]: 'ageFrom',
    [FIELDS.age_to]: 'ageTo',
    [FIELDS.exclude_tags]: 'excludeTags',
    [FIELDS.time_zone]: 'timezone',
    [FIELDS.target_ctr]: 'targetCtr',
    [FIELDS.target_cpa]: 'targetCpa',
    [FIELDS.min_cpm]: 'minCpm',
    [FIELDS.max_cpm]: 'maxCpm',
  }

  get targeting () {
    return {
      [FIELDS.start]: this.model[FIELDS.start],
      [FIELDS.end]: this.model[FIELDS.end],
      [FIELDS.streamers]: this.model[FIELDS.streamers],
      [FIELDS.exclude_streamers]: this.model[FIELDS.exclude_streamers],
      [FIELDS.broadcaster_languages]: this.model[FIELDS.broadcaster_languages],
      [FIELDS.exclude_languages]: this.model[FIELDS.exclude_languages],
      [FIELDS.countries]: this.model[FIELDS.countries],
      [FIELDS.exclude_countries]: this.model[FIELDS.exclude_countries],
      [FIELDS.age_from]: this.model[FIELDS.age_from],
      [FIELDS.age_to]: this.model[FIELDS.age_to],
      [FIELDS.gender]: this.model[FIELDS.gender],
      [FIELDS.mature]: this.model[FIELDS.mature],
      [FIELDS.tags]: this.model[FIELDS.tags],
      [FIELDS.exclude_tags]: this.model[FIELDS.exclude_tags],
      [FIELDS.agencies]: this.model[FIELDS.agencies],
      [FIELDS.min_cpm]: this.model[FIELDS.min_cpm],
      [FIELDS.max_cpm]: this.model[FIELDS.max_cpm],
    }
  }

  static setOrDefault (obj, key) {
    return obj?.[key] ?? this.schema[key]
  }

  static parseRaw (data) {
    return {
      [FIELDS.id]: this.setOrDefault(data, FIELDS.id),
      [FIELDS.slug]: this.setOrDefault(data, FIELDS.slug),
      [FIELDS.campaign]: data.campaign?.slug || this.schema[FIELDS.campaign],
      [FIELDS.campaignCategoryId]: data.campaign?.category?.id || this.schema[FIELDS.campaignCategoryId],
      [FIELDS.campaignCategoryIsDarkMarket]: data.campaign?.category?.is_dark_market || this.schema[FIELDS.campaignCategoryIsDarkMarket],
      [FIELDS.currency]: data.campaign?.advertiser?.wallet.currency,
      [FIELDS.title]: this.setOrDefault(data, FIELDS.title),
      [FIELDS.title_alternative]: this.setOrDefault(data, FIELDS.title_alternative),
      [FIELDS.description]: this.setOrDefault(data, FIELDS.description),
      [FIELDS.platform]: this.setOrDefault(data, FIELDS.platform),
      [FIELDS.format]: data.format?.id || this.schema[FIELDS.format],
      [FIELDS.format_edit]: typeof data[FIELDS.format_edit] === 'boolean' ? data[FIELDS.format_edit] : this.schema[FIELDS.format_edit],
      [FIELDS.bid_cap]: this.setOrDefault(data, FIELDS.bid_cap),
      [FIELDS.impressions]: this.setOrDefault(data, FIELDS.impressions),

      [FIELDS.start]: this.setOrDefault(data, FIELDS.start),
      [FIELDS.end]: this.setOrDefault(data, FIELDS.end),

      [FIELDS.frequency]: this.setOrDefault(data, FIELDS.frequency),
      [FIELDS.frequency_count]: this.setOrDefault(data, FIELDS.frequency_count),
      [FIELDS.frequency_period]: this.setOrDefault(data, FIELDS.frequency_period),

      [FIELDS.streamers]: this.setOrDefault(data.targeting, FIELDS.streamers),
      [FIELDS.exclude_streamers]: this.setOrDefault(data.targeting, FIELDS.exclude_streamers),

      [FIELDS.broadcaster_languages]: this.setOrDefault(data.targeting, FIELDS.broadcaster_languages),
      [FIELDS.exclude_languages]: this.setOrDefault(data.targeting, FIELDS.exclude_languages),

      [FIELDS.countries]: this.setOrDefault(data.targeting, FIELDS.countries),
      [FIELDS.exclude_countries]: this.setOrDefault(data.targeting, FIELDS.exclude_countries),

      [FIELDS.age_from]: this.setOrDefault(data.targeting, FIELDS.age_from),
      [FIELDS.age_to]: this.setOrDefault(data.targeting, FIELDS.age_to),

      [FIELDS.gender]: this.setOrDefault(data.targeting, FIELDS.gender),
      [FIELDS.mature]: this.setOrDefault(data.targeting, FIELDS.mature),

      [FIELDS.tags]: this.setOrDefault(data.targeting, FIELDS.tags),
      [FIELDS.exclude_tags]: this.setOrDefault(data.targeting, FIELDS.exclude_tags),
      [FIELDS.time_zone]: this.setOrDefault(data, FIELDS.time_zone),

      [FIELDS.target_ctr]: this.setOrDefault(data, FIELDS.target_ctr),
      [FIELDS.target_cpa]: this.setOrDefault(data, FIELDS.target_cpa),

      [FIELDS.agencies]: data.targeting?.agencies.map(agency => agency.id) || [],

      [FIELDS.min_cpm]: this.setOrDefault(data.targeting, FIELDS.min_cpm),
      [FIELDS.max_cpm]: this.setOrDefault(data.targeting, FIELDS.max_cpm),

    }
  }
}
